import React from 'react';
import ButtonGroup from '../button/ButtonGroup';
import RichText from '../RichText/RichText';

import { callout as sectionStyle, pink, orange, purple, green } from "./Callout.module.scss";

const Callout = ({ blok }) => {
    let calloutStyle = sectionStyle;

    if (blok.color) {
        switch (blok.color) {
            case "pink":
                calloutStyle = `${sectionStyle} ${pink}`;
                break;
            case "orange":
                calloutStyle = `${sectionStyle} ${orange}`;
                break;
            case "purple":
                calloutStyle = `${sectionStyle} ${purple}`;
                break;
            case "green":
                calloutStyle = `${sectionStyle} ${green}`;
                break;
            default:
                calloutStyle = `${sectionStyle} ${pink}`;
                break;
        }
    }

    return (
        <section className={calloutStyle}>
            {blok.isTitleOn && <h4>{blok.title}</h4>}
            <RichText content={blok.content} />
            <ButtonGroup buttonGroup={blok.button_group} align="center" />
        </section>
    )
};

export default Callout;