import React from 'react';
import ArticleCard from './ArticleCard';

import { articleCardGroup as containerStyle, section as sectionStyle } from "./ArticleCardGroup.module.scss";

const ArticleCardGroup = ({ blok, attachedBlokData }) => {
    
    let articleCards = attachedBlokData.map((postData, i) => {
        let content = JSON.parse(postData.node.content);

        let link = postData.node.slug ? postData.node.slug : (postData.node.full_slug ? postData.node.full_slug : "");

        let blurb = content.heading;
        let image = content.coverImage.filename;

        let buttonTitle = blok.button_title ? blok.button_title : "Read case study";

        return <ArticleCard content={{ blurb: blurb, "button_title": buttonTitle, link: link }} image={image} key={`ArticleCard-${i}`} />
    });

    return (
        <article className={sectionStyle}>
            <h2>{blok.title}</h2>
            <p>{blok.description}</p>
            <section className={containerStyle}>
                {
                    articleCards.map((articleCard) => articleCard)
                }
            </section>
        </article>
    )
};

export default ArticleCardGroup;