import React from "react";
import { sbEditable } from '@storyblok/storyblok-editable';

import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../Layout/Layout";
import Button from '../button/Button';
import Column from '../grid/column/column';
import TextHighlight from "../RichText/TextHighlight";
import Section from "../section/Section";
import FeatureCard from "../featurecard/FeatureCard";
import FeatureCardGroup from "../featurecard/FeatureCardGroup";
import RichText from "../RichText/RichText";
import Heading from "../RichText/Heading/Heading";
import HeroHeader from "../header/HeroHeader";
import MediaText from "../mediatext/MediaText";
import Callout from "../callout/Callout";
import ButtonGroup from "../button/ButtonGroup";
import ArticleCardGroup from "../articleCard/ArticleCardGroup";

const componentResolver = (blok, attachedBlokData = {}) => {

    const Components = {
        'Layout': Layout,
        'HeroHeader': HeroHeader,
        'Columns': Column,
        'Button': Button,
        'ButtonGroup': ButtonGroup,
        'Image': GatsbyImage,
        'TextHighlight': TextHighlight,
        'Section': Section,
        'RichTextEditor': RichText,
        'Heading': Heading,
        'FeatureCard': FeatureCard,
        'FeatureCardGroup': FeatureCardGroup,
        'MediaText': MediaText,
        'Callout': Callout,
        'ArticleCardGroup': ArticleCardGroup
    };

    let containerList = ["Layout", "RichTextEditor", "Section", "Columns"];

    if (typeof Components[blok.component] !== 'undefined') {
        
        let provideComponentData = attachedBlokData && attachedBlokData.hasOwnProperty("component") ? (attachedBlokData.component === blok.component ? true : false) : false;


        const Component = Components[blok.component];
        const componentIsContainer = containerList.includes(blok.component)
        let FinalComponent = <Component blok={blok} />;

        if (provideComponentData && componentIsContainer) {
            FinalComponent = <Component blok={blok} attachedBlokData={attachedBlokData} componentResolver={componentResolver} />
        }
        else if (!provideComponentData && componentIsContainer) {
            FinalComponent = <Component blok={blok} attachedBlokData={attachedBlokData} componentResolver={componentResolver} />
        }
        else if (provideComponentData && !componentIsContainer) {
            FinalComponent = <Component blok={blok} attachedBlokData={attachedBlokData.data} />
        }
        else {
            FinalComponent = FinalComponent;
        }

        return (<div {...sbEditable(blok)}>{FinalComponent}</div>)
    }
    
    return (<p>The component <strong>{blok.component}</strong> has not been created yet.</p>)
}

const DynamicComponent = ({ blok, attachedBlokData = {} }) => {
    return componentResolver(blok, attachedBlokData);
};

export default DynamicComponent;