import React from 'react';
import FeatureCard from './FeatureCard';

import { cardGroup as cardGroupStyle, groupItem, vertical as verticalStyle, simple as simpleStyle, col3, col4, col6 } from "./FeatureCardGroup.module.scss";

const FeatureCardGroup = ({ blok }) => {

    let cardsContainerStyle = cardGroupStyle;
    let containerDirection = "horizontal";
    //direction
    //personality
    //cards per row
  
    if (blok.direction) {
        if (blok.direction === "vertical") {
            cardsContainerStyle = `${cardGroupStyle} ${verticalStyle}`
            containerDirection = "vertical";
        }
    }

    if (blok.personality) {
        if (blok.personality === "simple") {
            cardsContainerStyle = `${cardsContainerStyle} ${simpleStyle}`;
        }
    }


    let groupItemClass = `${groupItem} ${containerDirection === "vertical" ? verticalStyle : ""}`;
    if (blok.cards_per_row) {

        switch (blok.cards_per_row) {
            case "2":
                groupItemClass = `${groupItemClass} ${col6}`
                break;
            case "4":
                groupItemClass = `${groupItemClass} ${col3}`
                break;
            default:
                groupItemClass = `${groupItemClass} ${col4}`
        }

    }
    
    return (
        <div className={cardsContainerStyle}>
            {
                blok.CardGroup.map((card, i) => {
                    let colour = "none";
                    if (card.color) {
                        if (card.color !== "none") {
                            colour = card.color;
                        }
                    }
                    return (
                        <div className={groupItemClass} key={card._uid}>
                            <FeatureCard
                                icon={card.icon ? (card.icon.length > 0 ? card.icon[0].icon : "") : ""}
                                isIconEnabled={card.icon_enabled}
                                cardImg={card.card_img}
                                colour={colour}
                                title={card.title}
                                description={card.description}
                                containerDirection={containerDirection}
                                imageStyle={{
                                    maxWidth: card.image_maxwidth ? card.image_maxwidth : null,
                                    maxHeight: card.image_maxheight ? card.image_maxheight: null,
                                    objectfit: card.image_backgroundfit ? card.image_backgroundfit: null
                                }}
                                order={blok.personality ? ( blok.personality === "simple" ? null : i + 1) : i + 1}
                                button_group={card.button_group ? card.button_group : [] } />
                        </div>
                    );
                })
            }
        </div>
    )
};

export default FeatureCardGroup;