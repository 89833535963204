import React from 'react';

import { pink, orange, green, purple } from './TextHighlight.module.scss';

const TextHighlight = ({ text, color, uniqueKey }) => {
    let currentStyle = null;
    if (color) {
        let colour = color;
        if (colour === "pink") {
            currentStyle = pink;
        }
        else if (colour === "orange") {
            currentStyle = orange;
        }
        else if (colour === "green") {
            currentStyle = green;
        }
        else {
            currentStyle = purple;
        }
    }
    else {
        currentStyle = pink;
    }

    return (
        <mark className={currentStyle} key={uniqueKey}>{text}</mark>
    )
};

export default TextHighlight;