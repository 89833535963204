import React from 'react';
import DynamicComponent from '../../dynamicComponent/DynamicComponent';

import { column as columnStyle } from "./column.module.scss";

const column = ({ children, blok }) => {
  if (children) {
    return (
      <div className={columnStyle}>
        {children} 
      </div>
    )
  }
  return (
    <div className={columnStyle}>
      {
        blok.columns.map((node, key) => {
          return <div key={`blok-${key}`}>
            <DynamicComponent blok={node} />
          </div>
        })
      }
    </div>
  );
};

export default column;