import React from 'react';
import TextHighlight from '../TextHighlight';

const Heading = ({ node }) => {
    let getText = node.content.map((child, i) => {

            if (child.marks) {
                return <TextHighlight color={child.marks[0].attrs.class} text={child.text} key={`Heading-${i}`} />
            }
            return child.text;
        });
        
    let level = node.attrs.level;

    let element = null;
    switch (level) {
        case 1:
            element = <h1>{getText}</h1>
            break;
        case 3:
            element = <h3>{getText}</h3>
            break;
        case 4:
            element = <h4>{getText}</h4>
            break;
        case 5:
            element = <h5>{getText}</h5>
            break;
        case 6:
            element = <h6>{getText}</h6>
            break;
        case 2:
        default:
            element = <h2>{getText}</h2>
            break;
    }

    return (
        <>
            {element}
        </>
    )
};

export default Heading;