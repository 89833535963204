import React from 'react';
import ButtonGroup from '../button/ButtonGroup';
import RichText from '../RichText/RichText';

import { mediaText as mediaTextStyle, reverse as reverseStyle, content as contentStyle } from "./MediaText.module.scss";

const MediaText = ({ blok }) => {
    let variant = blok.variant;

    let displayText = <>
        <h3>{blok.heading}</h3>
        <p>{blok.description}</p>
    </>

    if (variant) {
        if (variant === "richTextContent") {
            displayText = <RichText content={blok.content} />
        }
    }

    return (
        <div className={mediaTextStyle}>
            <div className={blok.isReversed ? reverseStyle : ""}>
                <img src={blok.media.filename} alt={blok.media.alt} />
            </div>
            <div className={contentStyle}>
                {displayText}
                <ButtonGroup buttonGroup={blok.button_group} />
            </div>
        </div>
    )
};

export default MediaText;