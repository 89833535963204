import React from 'react';
import * as blockquoteStyle from "./Blockquote.module.scss";

const Blockquote = ({ node, _parseNodeToJSX }) => {
    
    

    let containerStyle = "";

    //node.content.map(nodeChild => _parseNodeToJSX(nodeChild))
    const displayElements = node.content.map((nodeChild, i) => {
        const style = nodeChild.content.reduce((styleFound, contentNode, currentIndex, array) => {
            let style = contentNode?.marks;
            
            if (typeof(style) !== "undefined") {
                if (style[0].attrs.class) {
                    let newArray = array
                    delete newArray[currentIndex].marks;
                    currentIndex = nodeChild.length;
                    return {
                        found: true,
                        "color": style[0].attrs.class,
                        newContentArray: newArray
                    };
                }
            }

            return false;

        }, false);

        if (!style) {
            return _parseNodeToJSX(nodeChild, i)
        }

        nodeChild.content = style.newContentArray;
        
        const getContainerStyle = (style) => {
            switch (style) {
                case "pink":
                    return blockquoteStyle.pink;
                case "orange":
                    return blockquoteStyle.orange;
                case "purple":
                    return blockquoteStyle.purple;
                case "green":
                    return blockquoteStyle.green;
                case "none":
                default:
                    return ""
            }
        };

        containerStyle = getContainerStyle(style.color)

        return _parseNodeToJSX(nodeChild, i)
    });

    return (
        <blockquote className={`${blockquoteStyle.blockquote} ${containerStyle}`}>
            {
                displayElements.map(element => element)
            }
        </blockquote>
    )
};

export default Blockquote;