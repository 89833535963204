// extracted by mini-css-extract-plugin
export var buttonGroup = "FeatureCard-module--button-group--mvsZ8";
export var content = "FeatureCard-module--content--ArUaP";
export var featureCard = "FeatureCard-module--feature-card--2fNW+";
export var green = "FeatureCard-module--green--VHv2k";
export var icon = "FeatureCard-module--icon--IcfUQ";
export var iconContainer = "FeatureCard-module--icon-container--N-ZXr";
export var iconContainerGreen = "FeatureCard-module--icon-container-green--V2zLB";
export var iconContainerOrange = "FeatureCard-module--icon-container-orange--7PrXE";
export var iconContainerPurple = "FeatureCard-module--icon-container-purple--dfhhy";
export var iconContainerRed = "FeatureCard-module--icon-container-red--2Sqg+";
export var image = "FeatureCard-module--image--R0KTB";
export var mw100 = "FeatureCard-module--mw-100--2JXja";
export var mw30 = "FeatureCard-module--mw-30--5KzHS";
export var mw50 = "FeatureCard-module--mw-50--LBVwz";
export var mw75 = "FeatureCard-module--mw-75--RhPir";
export var mw90 = "FeatureCard-module--mw-90--MnLGA";
export var mwAuto = "FeatureCard-module--mw-auto--e+tc8";
export var mwContent = "FeatureCard-module--mw-content--saygI";
export var mwMaxContent = "FeatureCard-module--mw-max-content--Dd3Md";
export var mwMinContent = "FeatureCard-module--mw-min-content--Xj1le";
export var mwNone = "FeatureCard-module--mw-none--GaE31";
export var orange = "FeatureCard-module--orange--GqjMz";
export var purple = "FeatureCard-module--purple--sjS3f";
export var red = "FeatureCard-module--red--vZjQN";
export var small = "FeatureCard-module--small--peh+q";
export var vertical = "FeatureCard-module--vertical--y+0RE";