import React from 'react';
import Button from '../button/Button';
import ButtonGroup from '../button/ButtonGroup';
import { articleCard as articleCardStyle, imageContainer, content as contentStyle } from "./ArticleCard.module.scss";

const ArticleCard = ({ content, image }) => {
    return (
        <article className={articleCardStyle}>
            <div className={imageContainer}>
                <img src={image} alt={content.title ? content.title : ""}/>
            </div>
            <div className={contentStyle}>
                <p>{content.blurb}</p>
                <ButtonGroup>
                    <Button url={content.link? content.link : "/#"} title={content.button_title ? content.button_title : "Read"} type="link" variant="secondary"/>
                </ButtonGroup>
            </div>
        </article>
    )
};

export default ArticleCard;