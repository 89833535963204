import React from 'react';
import Button from './Button';

import { sbEditable } from "@storyblok/storyblok-editable";
import DynamicComponent from "../../components/dynamicComponent/DynamicComponent";


import { buttonGroup as buttonGroupStyle, center as centered } from "./ButtonGroup.module.scss";

const ButtonGroup = ({ buttonGroup, children, align }) => {

    let groupStyle = align ? (align == "center" ? `${buttonGroupStyle} ${centered}` : buttonGroupStyle) : buttonGroupStyle;

    if (children) {
        return <div className={groupStyle} {...sbEditable(buttonGroup)}>{children ? children : components}</div>
    }
    else {

    }
    const components = buttonGroup ? buttonGroup.map(blok => {
        return (<DynamicComponent blok={blok} key={blok._uid} />)
    }) : "";

    return (
        <div className={groupStyle} {...sbEditable(buttonGroup)}>
            {children ? children : components}
        </div>
    )
};

export default ButtonGroup;