import * as React from "react";

import { header as headerStyle, bgCover as bgCoverStyle, tag as tagStyle, content, heroImage } from "./HeroHeader.module.scss";

import RichText from "../RichText/RichText";
import ButtonGroup from "../button/ButtonGroup";

const HeroHeader = ({ blok, parentName }) => {
  const image = blok.bgImg.filename;

  const buttonGroup = blok.button_group;

  let displayDescription = <p>{blok.description}</p>;
  if(blok.description) {
    if(blok.description.type === "doc") {
      displayDescription =  <RichText content={blok.description} />
    } 
  }

  return (
    <header className={`${headerStyle} ${blok.variant ? (blok.variant === "banner-cover" ? bgCoverStyle : "") : ""}`}>
      <div className={heroImage}>
        <img src={image} alt={blok.bgImg.alt} />
      </div>
      <div className={content}>

        <h1 className={parentName ? (parentName === "case-study" ? tagStyle: ""): ""}>{blok.title}</h1>
        {displayDescription}
        <ButtonGroup buttonGroup={buttonGroup} />

      </div>
    </header>
  )
};

export default HeroHeader;
