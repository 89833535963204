import React from 'react';
import ButtonGroup from '../button/ButtonGroup';

import * as featureCardStyle from "./FeatureCard.module.scss";

const FeatureCard = (props) => {

    let colour = { card: null, icon: null };

    const getColourStyle = () => {
        let colour = props.colour ? props.colour : "";
        let accentColours = ["green", "red", "orange", "purple"];
        let style = { card: "", icon: "" };

        if (accentColours.includes(colour)) {
            if (colour === "green") {
                style = { card: featureCardStyle.green, icon: featureCardStyle.iconContainerGreen };
            }
            else if (colour === "red") {
                style = { card: featureCardStyle.red, icon: featureCardStyle.iconContainerRed };
            }
            else if (colour === "purple") {
                style = { card: featureCardStyle.purple, icon: featureCardStyle.iconContainerPurple };
            }
            else {
                style = { card: featureCardStyle.orange, icon: featureCardStyle.iconContainerOrange };
            }
        }

        return style;
    }

    const getDimensionStyle = () => {
        let order = props.order ? props.order : null;

        let cardIsSmall = (order) => {
            let difference = 1;
            let differenceLimit = 2;
            let isSmall = false;

            let i = 1;
            while (i <= order) {
                if (i === order)
                    isSmall = true;
                difference = difference >= differenceLimit ? 0 : difference + 1
                i = (i + 1) + (difference);
            }
            return isSmall;
        };

        if (order) {
            if (cardIsSmall(order)) {
                return (featureCardStyle.small);
            }
        }

        return "";
    }

    const getDirectionStyle = () => {
        let containerDirection = props.containerDirection;
        if (containerDirection) {
            if (containerDirection === "vertical") {
                return (featureCardStyle.vertical);
            }
        }
        return "";
    }

    const getRenderStyle = () => {
        let colourStyle = { card: "", icon: "" };

        if (colour.card == null) {
            colourStyle = getColourStyle();
            colour = colourStyle;
        }
        else {
            colourStyle = colour.card;
        }
        let directionStyle = getDirectionStyle();

        let dimensionStyle = "";
        if (directionStyle !== "") {//only if card style is vertical
            dimensionStyle = getDimensionStyle();
        }

        let finalCardStyle = [featureCardStyle.featureCard, colourStyle.card, dimensionStyle, directionStyle];

        return `${finalCardStyle.map(cardStyle => ` ${cardStyle} `)}`;
    }

    const getIconStyle = () => {
        if (colour.icon == null) {
            let colourStyle = getColourStyle();
            colour = colourStyle;
            return colourStyle.icon;
        }
        else {
            return colour.icon;
        }
    }

    const getDisplayMedia = () => {
        if (props.isIconEnabled && props.icon) {
            return <span className={`${featureCardStyle.iconContainer} ${getIconStyle()}`}><i className={`icon icon-${props.icon} ${featureCardStyle.icon}`}></i></span>;
        }
        const getImageStyle = () => {
            let imageStyle = ``;

            if (props.imageStyle) {
                if (props.imageStyle.maxWidth) {
                    let maxWidth = props.imageStyle.maxWidth;
                
                    if (maxWidth.match(/[0-9]+%/g).length > 0) {
                        imageStyle = `${imageStyle} ${featureCardStyle["mw" + maxWidth.match(/[0-9]+/g)[0]]}`
                    }
                    else {
                        let formatted = (maxWidth.charAt(0).toUpperCase() + maxWidth.slice(1));
                        imageStyle = `${imageStyle} ${featureCardStyle["mw" + formatted]}`
                    }
                }
            }

            return imageStyle;
        };

        return <img src={props.cardImg.filename} alt={props.cardImg.alt} className={getImageStyle()} />;
    }

    return (
        <section className={getRenderStyle()}>
            <div className={featureCardStyle.image}>
                {props.cardImg ? getDisplayMedia() : ""}
            </div>
            <div className={featureCardStyle.content}>
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            </div>
            <div className={featureCardStyle.buttonGroup}>
                {props.button_group ? <ButtonGroup buttonGroup={props.button_group} /> : ""}
            </div>
        </section>
    );

}

export default FeatureCard;