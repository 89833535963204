import { Link } from 'gatsby';
import React from 'react';
import Blockquote from './Blockquote/Blockquote';
import Blok from './Blok/Blok';

import Heading from './Heading/Heading';
import TextHighlight from './TextHighlight';

const RichText = ({ content, blok }) => {
    content = content ? content : blok;

    let exitRecursive = false;
    while ((typeof content) === "object" && !Array.isArray(content)) {
        if (content.hasOwnProperty("content")) {

        }
        else {
            exitRecursive = false;
            content = [{ type: "none" }];
        }
        content = content.content;
    }
    let counter = 1;

    const _parseNodeToJSX = (node, key) => {

        switch (node.type) {
            case "blok":
                return <Blok node={node} key={`blok-${key}`} _parseNodeToJSX={_parseNodeToJSX} />;
            case "blockquote":
                counter = counter + 1;
                return <Blockquote node={node} key={`blockquote-${key}`} _parseNodeToJSX={_parseNodeToJSX} />
            case "heading":
                return <Heading node={node} key={`RichText-${key}`} _parseNodeToJSX={_parseNodeToJSX} />
            case "paragraph":
            default:
                return <p key={`paragraph-${key}`}>
                    {
                        node.content.map((child, i) => {

                            if (child.marks) {

                                if (child.marks[0].type === "link") {
                                    if (child.marks[0].attrs.linktype === "story")
                                        return <Link to={child.marks[0].attrs.href}>{child.text}</Link>
                                    return <a href={child.marks[0].attrs.href}>{child.text}</a>
                                }

                                return <TextHighlight color={child.marks[0].attrs.class} text={child.text} key={`RichText-${i}`} uniqueKey={`TextHighlight-${i}`} />
                            }
                            return child.text;
                        })
                    }
                </p>
        }

    };
    
    return (
        <>
            {
                content.map((node, j) => {
                    return _parseNodeToJSX(node, j);
                })
            }
        </>
    );
};

export default RichText;