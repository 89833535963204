import React from 'react';
import ArticleCardGroup from '../articleCard/ArticleCardGroup';
import RichText from '../RichText/RichText';

import { section, leftAligned as leftAlignedStyle, postContent as postContentStyle } from "./Section.module.scss"

const Section = ({ blok, children, attachedBlokData, variant, componentResolver }) => {

    let displayElement = null;

    let resolveComponent = (element, blok, attachedBlokData = {}) => {

        let displayElement = element;
        if (displayElement === null) {
            displayElement = componentResolver(blok, attachedBlokData);
        }
        else {
            displayElement = <>
                {displayElement}
                {componentResolver(blok, attachedBlokData)}
            </>;
        }

        return displayElement;
    }
    
    if (blok) {
        variant = blok.variant;

        if (blok.content !== "") {
            displayElement = <RichText content={blok.content} />;
        }
        if (blok.blok_attachment) {
            if (blok.blok_attachment.length > 0) {

                blok.blok_attachment.forEach((childBlok, i) => {
                    displayElement = resolveComponent(displayElement, childBlok, attachedBlokData);
                });

            }
        }
    }
    else {
        displayElement = children;
    }
    let sectionStyle = `${section} ${variant ? (variant === "post-content" ? `${leftAlignedStyle} ${postContentStyle}` : "") : ""}`
    return (
        <section className={sectionStyle}>
            {displayElement}
        </section>
    )
};

export default Section;