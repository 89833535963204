import React from 'react';
import Button from '../../button/Button';
import FeatureCardGroup from '../../featurecard/FeatureCardGroup';
import MediaText from '../../mediatext/MediaText';
import ArticleCardGroup from '../../articleCard/ArticleCardGroup';

const Blok = ({ node }) => {
  return (
    node.attrs.body.map((child, i) => {
      switch (child.component) {
        case "MediaText":
          return <MediaText blok={child} key={`MediaText-${i}`} />

        case "Button":
          return <Button title={child.title} type={child.type} url={child.url} variant={child.variant} key={`Button-${child._uid}`} />

        case "ArticleCardGroup":
          return <ArticleCardGroup blok={child} />

        case "CardGroup":
        default:
          return <FeatureCardGroup blok={child} key={`CardGroup-${i}`} />
      }
    })
  )
};

export default Blok;